import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Send, Plus, Menu, Loader2, LogOut as LogoutIcon } from 'lucide-react';
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { ScrollArea } from "./ui/scroll-area";
import axios from 'axios';
import OrderCard from './OrderCard';
import ChatHistory from './ChatHistory';
import ReactMarkdown from 'react-markdown';

const getAuthHeaders = () => ({
    'Authorization': `Bearer ${localStorage.getItem('token')}`
});

export default function Chat() {
    const username = localStorage.getItem('username');
    const [chatElements, setChatElements] = useState([
        { type: 'message', content: { role: 'assistant', content: `¡Hola, ${username}! ¿En qué puedo ayudarte hoy? 🌟` }, timestamp: Date.now(), order: 0 },
    ]);
    const [ordersFetched, setOrdersFetched] = useState(false);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const textareaRef = useRef(null);
    const scrollAreaRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            const newHeight = Math.min(textareaRef.current.scrollHeight, 150);
            textareaRef.current.style.height = `${newHeight}px`;
        }
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollTo(0, scrollAreaRef.current.scrollHeight);
        }
        scrollToBottom();
    }, [input, chatElements]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const fetchOrders = useCallback(async () => {
        if (ordersFetched) return;
        try {
            const response = await axios.get('/api/orders', {
                headers: getAuthHeaders()
            });

            const sortedOrders = response.data.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));

            const orderElements = sortedOrders.map((order, index) => ({
                type: 'order',
                content: order,
                timestamp: new Date(order.date_created).getTime(),
                order: chatElements.length + index
            }));

            setChatElements(prev => [...prev, ...orderElements]);
            setOrdersFetched(true);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }, [ordersFetched, chatElements]);

    const handleSend = useCallback(async () => {
        if (input.trim()) {
            const userMessage = {
                type: 'message',
                content: { role: 'user', content: input },
                timestamp: Date.now(),
                order: chatElements.length
            };
            setChatElements(prev => [...prev, userMessage]);
            setInput('');
            setIsLoading(true);

            try {
                const response = await axios.post('/api/chat', {
                    message: input,
                    threadId: threadId
                }, {
                    headers: getAuthHeaders()
                });

                const { message, orders, threadId: newThreadId } = response.data;

                if (message && typeof message === 'string') {
                    const botMessage = {
                        type: 'message',
                        content: { role: 'assistant', content: message },
                        timestamp: Date.now(),
                        order: chatElements.length + 1
                    };
                    setChatElements(prev => [...prev, botMessage]);
                }

                if (orders && Array.isArray(orders)) {
                    const orderElements = orders.map((order, index) => ({
                        type: 'order',
                        content: order,
                        timestamp: new Date(order.date_created).getTime(),
                        order: chatElements.length + index + 2
                    }));
                    setChatElements(prev => [...prev, ...orderElements]);
                }

                setThreadId(newThreadId);

                const firstUserMessage = chatElements.find(element => element.type === 'message' && element.content.role === 'user');
                const title = firstUserMessage
                    ? firstUserMessage.content.content.substring(0, 50) + '...'
                    : 'Chat con AnaApp';

                await axios.post('/api/save-chat', {
                    threadId: newThreadId,
                    title: title
                }, {
                    headers: getAuthHeaders()
                });

                console.log('Chat saved successfully.');
            } catch (error) {
                console.error('Error sending message:', error);
                alert('Hubo un error al enviar el mensaje. Por favor, intenta de nuevo.');
            } finally {
                setIsLoading(false);
            }
        }
    }, [input, threadId, chatElements]);

    const saveChat = async (threadId, title) => {
        if (!threadId) return;
        try {
            const response = await axios.get('/api/chat-history', {
                headers: getAuthHeaders()
            });

            const existingChat = response.data.find(chat => chat.thread_id === threadId);
            if (existingChat) {
                console.log('Chat already exists:', existingChat);
                return;
            }

            const saveResponse = await axios.post('/api/save-chat', { threadId, title }, {
                headers: getAuthHeaders()
            });
            console.log('Chat saved successfully:', saveResponse.data);
        } catch (error) {
            console.error('Error saving chat:', error.response?.data || error.message);
            alert('Hubo un error al guardar el chat. Por favor, intenta de nuevo.');
        }
    };

    const handleNewChat = () => {
        setChatElements([
            { type: 'message', content: { role: 'assistant', content: `¡Hola, ${username}! ¿En qué puedo ayudarte hoy? 🌟` }, timestamp: Date.now(), order: 0 },
        ]);
        setThreadId(null);
    };

    const handleSelectChat = async (selectedThreadId) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`/api/messages/${selectedThreadId}`, {
                headers: getAuthHeaders()
            });

            const fetchedElements = response.data.messages.map((msg, index) => ({
                type: msg.type,
                content: msg.type === 'message' ? { role: msg.role, content: msg.content } : msg.content,
                timestamp: new Date(msg.timestamp).getTime(),
                order: index
            }));

            setChatElements(fetchedElements);
            setThreadId(selectedThreadId);
            setShowHistory(false);
        } catch (error) {
            console.error('Error loading selected chat:', error);
            alert('Hubo un error al cargar el chat seleccionado.');
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdateStatus = (orderId, newStatus, updatedOrder) => {
        setChatElements(prevElements =>
            prevElements.map(element =>
                element.type === 'order' && element.content.id === orderId
                    ? { ...element, content: updatedOrder }
                    : element
            )
        );
    };

    const sortedChatElements = [...chatElements].sort((a, b) => a.order - b.order);

    const fetchChatHistory = async () => {
        try {
            const response = await axios.get('/api/chat-history', {
                headers: getAuthHeaders()
            });
            // Handle the fetched chat history if needed
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const handleDeleteChat = async (chatId) => {
        try {
            await axios.delete(`/api/delete-chat/${chatId}`, {
                headers: getAuthHeaders()
            });
            fetchChatHistory();
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('userId');
        window.location.href = '/';
    };

    return (
        <div className="flex flex-col h-screen bg-white text-black">
            <header className="flex justify-between items-center p-4 border-b border-gray-200 sticky top-0 bg-white z-10">
                <Button variant="ghost" size="icon" onClick={() => setShowHistory(!showHistory)}>
                    <Menu className="h-6 w-6" />
                </Button>
                <div className="flex items-center absolute left-1/2 transform -translate-x-1/2">
                    <img src="https://grancolombiacigars.com/wp-content/uploads/2024/08/LogoLabelsShippingPDF.png"
                        alt="Logo" className="h-14 mr-5" />
                    <h1 className="text-xl font-bold italic font-serif">AnaApp</h1>
                </div>
                <Button variant="ghost" size="icon" onClick={handleNewChat}>
                    <Plus className="h-6 w-6" />
                </Button>
            </header>

            <div className="flex-grow flex">
                {showHistory && (
                    <div className="w-64 border-r border-gray-200">
                        <div className="p-4 flex justify-between items-center">
                            <h2 className="text-lg font-semibold">Historial de Chats</h2>
                            <Button variant="ghost" size="icon" onClick={handleLogout}>
                                <LogoutIcon className="h-5 w-5" />
                            </Button>
                        </div>
                        <ChatHistory
                            onSelectChat={handleSelectChat}
                            onDeleteChat={handleDeleteChat}
                        />
                    </div>
                )}
                <div className="flex-grow flex justify-center overflow-hidden mb-8">
                    <div className="w-full max-w-3xl flex flex-col">
                        <ScrollArea className="flex-grow p-4 pb-32" ref={scrollAreaRef}>
                            <div className="space-y-4">
                                {sortedChatElements.map((element, index) => (
                                    <div key={index}>
                                        {element.type === 'message' && (
                                            <div className={`flex ${element.content.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                                                <div className={`max-w-[80%] p-3 rounded-lg ${element.content.role === 'user' ? 'bg-black text-white' : 'bg-gray-100 text-black'}`}>
                                                    <ReactMarkdown className="whitespace-pre-line markdown-content">
                                                        {element.content.content}
                                                    </ReactMarkdown>
                                                </div>
                                            </div>
                                        )}
                                        {element.type === 'order' && (
                                            <OrderCard order={element.content} onUpdateStatus={onUpdateStatus} />
                                        )}
                                    </div>
                                ))}

                                {isLoading && (
                                    <div className="flex justify-start">
                                        <div className="bg-gray-100 p-3 rounded-lg">
                                            <Loader2 className="h-6 w-6 animate-spin" />
                                        </div>
                                    </div>
                                )}
                                <div ref={messagesEndRef} />
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
                <div className="max-w-3xl mx-auto p-4">
                    <Button variant="outline" className="w-full mb-4" onClick={fetchOrders} disabled={ordersFetched}>
                        Ver productos pendientes
                    </Button>
                    <div className="flex items-end space-x-2">
                        <Textarea
                            ref={textareaRef}
                            placeholder="Escribe un mensaje..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), handleSend())}
                            className="flex-grow resize-none overflow-y-auto min-h-[2.5rem] max-h-[150px]"
                        />
                        <Button onClick={handleSend}>
                            <Send className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}