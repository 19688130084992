import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";

export default function ChatHistory({ onSelectChat, onDeleteChat }) {
    const [chats, setChats] = useState([]);

    useEffect(() => {
        fetchChatHistory();
    }, []);

    const fetchChatHistory = async () => {
        try {
            const response = await axios.get('/api/chat-history', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setChats(response.data);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const handleDeleteChat = async (chatId) => {
        try {
            await axios.delete(`/api/delete-chat/${chatId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            onDeleteChat(chatId);
            fetchChatHistory();
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    return (
        <Card className="w-64 h-full">
            <CardContent>
                <ScrollArea className="h-[calc(100vh-120px)]">
                    {chats.map((chat) => (
                        <div key={chat.id} className="flex justify-between items-center mb-2">
                            <Button
                                variant="ghost"
                                onClick={() => onSelectChat(chat.thread_id)}
                            >
                                {chat.title}
                            </Button>
                            <Button
                                variant="destructive"
                                size="sm"
                                onClick={() => handleDeleteChat(chat.id)}
                            >
                                Borrar
                            </Button>
                        </div>
                    ))}
                </ScrollArea>
            </CardContent>
        </Card>
    );
}