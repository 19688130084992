import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "src/components/ui/card";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Loader2 } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Textarea } from "src/components/ui/textarea";
import axios from 'axios';

function ShippingPopup({ isOpen, onClose, onComplete, isLoading }) {
    const [shippingCompany, setShippingCompany] = useState("");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState("");
    const [customerNote, setCustomerNote] = useState("");

    const handleComplete = () => {
        onComplete({
            shippingCompany,
            trackingNumber,
            estimatedDeliveryDate,
            customerNote
        });
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px] bg-white" aria-describedby="shipping-popup-description">
                <DialogHeader>
                    <DialogTitle>Completar Orden</DialogTitle>
                    <p id="shipping-popup-description" className="text-sm text-gray-500">
                        Ingrese los detalles de envío para completar la orden.
                    </p>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="shipping-company" className="text-right">
                            Empresa que realizará el envío
                        </Label>
                        <Select onValueChange={setShippingCompany} value={shippingCompany}>
                            <SelectTrigger className="col-span-3 bg-white">
                                <SelectValue placeholder="Seleccione una empresa" />
                            </SelectTrigger>
                            <SelectContent className="bg-white">
                                <SelectItem value="Gran Colombia Shipping">Gran Colombia Shipping</SelectItem>
                                <SelectItem value="Servientrega">Servientrega</SelectItem>
                                <SelectItem value="Interrapidismo">Interrapidismo</SelectItem>
                                <SelectItem value="Otro">Otro</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="tracking-number" className="text-right">
                            Número de guía
                        </Label>
                        <Input
                            id="tracking-number"
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="estimated-delivery" className="text-right">
                            Fecha aproximada de entrega
                        </Label>
                        <Input
                            id="estimated-delivery"
                            value={estimatedDeliveryDate}
                            onChange={(e) => setEstimatedDeliveryDate(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="customer-note" className="text-right">
                            Nota para el cliente
                        </Label>
                        <Textarea
                            id="customer-note"
                            value={customerNote}
                            onChange={(e) => setCustomerNote(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                </div>
                <Button onClick={handleComplete} className="w-full" disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Procesando...
                        </>
                    ) : (
                        "Completar Orden"
                    )}
                </Button>
            </DialogContent>
        </Dialog>
    );
}

export default function OrderCard({ order, onUpdateStatus }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [completionError, setCompletionError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenPopup = () => setIsPopupOpen(true);
    const handleClosePopup = () => setIsPopupOpen(false);

    const handleComplete = async (shippingDetails) => {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/complete-order', {
                orderId: order.id,
                shippingDetails
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            onUpdateStatus(order.id, "completed", response.data.order);
            setCompletionError(false);
        } catch (error) {
            console.error('Error completing order:', error);
            alert('Error completing order. Please try again.');
            setCompletionError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // Determine if the order is completed
    const isOrderCompleted = order.status === 'completed';

    // Determine the card border color
    const cardBorderColor = completionError ? 'border-red-500' : isOrderCompleted ? 'border-green-500' : 'border-gray-200';

    // Check if the order is for local pickup
    const isLocalPickup = order.shipping_lines.some(line => line.method_id === 'local_pickup');

    return (
        <Card className={`w-full h-full shadow-lg mb-8 border-2 ${cardBorderColor}`}>
            <CardHeader className="border-b">
                <div className="flex justify-between items-center">
                    <CardTitle className="text-lg font-semibold">Orden {order.id}</CardTitle>
                    <Badge variant="secondary">
                        {order.status === "pending" ? "Pendiente de pago" :
                            order.status === "processing" ? "Procesando" :
                                order.status === "on-hold" ? "En Espera" :
                                    order.status === "completed" ? "Completado" :
                                        order.status === "cancelled" ? "Cancelado" :
                                            order.status === "refunded" ? "Reembolsado" :
                                                order.status === "failed" ? "Fallido" :
                                                    order.status}
                    </Badge>
                </div>
            </CardHeader>
            <CardContent className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2 mr-4">
                        <h3 className="text-sm font-medium text-gray-500 mt-3 mb-3">Productos comprados</h3>
                        <ul className="space-y-2">
                            {order.line_items.map((item) => (
                                <li key={item.id} className="flex items-center">
                                    <span className="text-gray-600">x{item.quantity.toString().toLowerCase()} -&nbsp;</span>
                                    <span dangerouslySetInnerHTML={{
                                        __html: item.name.split(' ').map(word =>
                                            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                        ).join(' ').replace(/X/g, 'x').replace(/\((.*?)\)/g, '<i>($1)</i>')
                                    }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="space-y-2">
                        <h3 className="text-sm font-medium text-gray-500 mt-3 mb-3">{isLocalPickup ? "Dejar en portería para" : "Detalles de envio"}</h3>
                        {isLocalPickup ? (
                            <>
                                <p className="text-sm">
                                    {order.billing.first_name} {order.billing.last_name}
                                </p>
                                <p className="text-sm">{order.billing.phone}</p>
                            </>
                        ) : (
                            <>
                                <p className="text-sm">
                                    {order.shipping.first_name} {order.shipping.last_name}
                                </p>
                                <p className="text-sm">{order.shipping.address_1}</p>
                                <p className="text-sm">{order.shipping.city}</p>
                                <p className="text-sm">{order.billing.phone}</p>
                            </>
                        )}
                    </div>
                    {order.customer_note && (
                        <div>
                            <h3 className="text-sm font-medium text-gray-500 mb-2">Nota del cliente</h3>
                            <p className="text-sm bg-gray-50 p-3 rounded">{order.customer_note}</p>
                        </div>
                    )}
                </div>
                <div className="mt-6">
                    <Button
                        onClick={handleOpenPopup}
                        className="w-full border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground"
                        disabled={isOrderCompleted || isLoading}
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Procesando...
                            </>
                        ) : (
                            "Completar Orden"
                        )}
                    </Button>
                </div>
                <ShippingPopup
                    isOpen={isPopupOpen}
                    onClose={handleClosePopup}
                    onComplete={handleComplete}
                    isLoading={isLoading}
                />
            </CardContent>
        </Card>
    );
}