import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card"

export default function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        try {
            const response = await axios.post('/api/login', { username, password })
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('username', response.data.username)
            localStorage.setItem('userId', response.data.userId)
            navigate('/chat')
        } catch (error) {
            setError('Usuario o contraseña incorrectos. Por favor, intenta de nuevo.');
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-white">
            <Card className="w-full max-w-md">
                <CardHeader className="space-y-1 text-center">
                    <div className="flex justify-center mb-4">
                        <img
                            src="https://grancolombiacigars.com/wp-content/uploads/2024/08/LogoLabelsShippingPDF.png"
                            alt="Logo"
                            className="h-20"
                        />
                    </div>
                    <h2 className="text-2xl font-bold tracking-tight">Bienvenido a AnaApp</h2>
                    <p className="text-sm text-muted-foreground">Ingresa tus credenciales para acceder a tu cuenta</p>
                </CardHeader>
                <form onSubmit={handleSubmit}>
                    <CardContent className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="username">Usuario</Label>
                            <Input
                                id="username"
                                placeholder="Ingresa tu usuario"
                                required
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="password">Contraseña</Label>
                            <Input
                                id="password"
                                placeholder="Ingresa tu contraseña"
                                required
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                    </CardContent>
                    <CardFooter className="flex flex-col space-y-4">
                        <Button className="w-full bg-black hover:bg-gray-800 text-white" type="submit">
                            Iniciar sesión
                        </Button>
                        <p className="text-sm text-muted-foreground text-center">¿Se te olvido la contraseña? Ponte en contacto con el <a href='https://wa.me/573013146030/' className='underline'><b>administrador</b></a>.</p>
                    </CardFooter>
                </form>
            </Card>
        </div >
    )
}